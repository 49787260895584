<template>
    <router-view/>
</template>

<script>
import Vue from 'vue'
import VueSocialauth from 'vue-social-auth'

// Vue.use(VueSocialauth, {
//     providers: {
//         github: {
//             clientId: '',
//             redirectUri: '/auth/github/callback' // Your client app URL
//         }
//     }
// })

export default {
    middleware: 'guest',
}
</script>

<style lang="scss">
</style>
